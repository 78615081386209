/* eslint-disable prettier/prettier */
import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';
// utils
import Axios from '../../utils/axiosInstance';

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  isLoading: false,
  error: false,
  userList: [],
  AllUserList : [],
  userProfile: [],
  ProfileReport: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE USER
    createUser(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    // DELETE USERS
    deleteUser(state, action) {
      const deleteUser = filter(state.userList, (user) => user.id !== action.payload);
      state.userList = deleteUser;
    },

    // GET USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },
    
    getAllUserListSuccess(state, action) {
      state.isLoading = false;
      state.AllUserList = action.payload;
    },
    getUserProfileSuccess(state, action) {
      state.isLoading = false;
      state.userProfile = action.payload;
    },
    getMonthlyUserProfileSuccess(state, action) {
      state.isLoading = false;
      state.ProfileReport = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteUser } = slice.actions;

// ----------------------------------------------------------------------

export function dispatchDeleteUser(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await Axios.delete(`/users/${userId}`);
      dispatch(slice.actions.deleteUser(userId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function dispatchNewUser(user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/users', user);
      dispatch(slice.actions.createUser(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function dispatchUpdateUser(user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/users/${user.id}`, user);
      dispatch(slice.actions.createUser(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/users');
      dispatch(slice.actions.getUserListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/users/all-without-filter');
      dispatch(slice.actions.getAllUserListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getUserProfile(profileId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const id = profileId;
      const response = await Axios.get(`/user_profile/${id}`);
      dispatch(slice.actions.getUserProfileSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMonthlyUserProfile(profileId, fromDate, toDate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const id = profileId;
      const response = await Axios.get(`/user_profile/${id}?from_date=${fromDate}&to_date=${toDate}`);
      dispatch(slice.actions.getMonthlyUserProfileSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
