import axios from 'axios';
import { API_BASE_URL } from '../config';

// const localURL = "http://localhost:5000";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  // baseURL: localURL
}); 

export default axiosInstance;

