import { createSlice } from '@reduxjs/toolkit';
import Axios from '../../utils/axiosInstance';

const initialState = {
  DiamondList: [],
  GemstoneList: [],
  diamondId: [],
  gemstoneId: [],
  unmountId: [],
  UnmountList: [],
  StockBydesignId: [],
  SetmountList: [],
  SetmountId: [],
  SetStockId: [],
  EarringsList: [],
  earringsId: [],
  PendantsList: [],
  PendantsId: [],
  pendants: null,
  BraceletList: [],
  BraceletId: [],
  bracelet: null,
  unmount: null,
  setmount: null,
  diamond: null,
  gemstone: null,
  earrings: null,
  isLoading: false,
  error: null
};

const slice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // INVENTORY DIAMONDS
    createDiamondInventory(state, action) {
      state.isLoading = false;
      state.diamond = action.payload;
    },
    inventoryDiamondList(state, action) {
      state.isLoading = false;
      state.DiamondList = action.payload;
    },
    diamondById(state, action) {
      state.isLoading = false;
      state.diamondId = action.payload;
    },
    // INVENTORY GEMSTONES
    createGemstoneInventory(state, action) {
      state.isLoading = false;
      state.gemstone = action.payload;
    },
    inventoryGemstoneList(state, action) {
      state.isLoading = false;
      state.GemstoneList = action.payload;
    },
    gemstoneById(state, action) {
      state.isLoading = false;
      state.gemstoneId = action.payload;
    },
    // INVENTORY UNSETMOUNT
    createUnmountInventory(state, action) {
      state.isLoading = false;
      state.unmount = action.payload;
    },
    getInventoryUnmount(state, action) {
      state.isLoading = false;
      state.UnmountList = action.payload;
    },
    getUnmountById(state, action) {
      state.isLoading = false;
      state.unmountId = action.payload;
    },
    getstockBydesignId(state, action) {
      state.isLoading = false;
      state.StockBydesignId = action.payload;
    },
    // INVENTORY SETMOUNT
    createSetmountInventory(state, action) {
      state.isLoading = false;
      state.setmount = action.payload;
    },
    getInventorySetmount(state, action) {
      state.isLoading = false;
      state.SetmountList = action.payload;
    },
    SetmountById(state, action) {
      state.isLoading = false;
      state.SetmountId = action.payload;
    },
    SetmountStockId(state, action) {
      state.isLoading = false;
      state.SetStockId = action.payload;
    },
    // INVENTORY EARRINGS
    createEarringsInventory(state, action) {
      state.isLoading = false;
      state.earrings = action.payload;
    },
    inventoryEarringsList(state, action) {
      state.isLoading = false;
      state.EarringsList = action.payload;
    },
    EarringsById(state, action) {
      state.isLoading = false;
      state.earringsId = action.payload;
    },
    // INVENTORY PENDANTS
    createPendantsInventory(state, action) {
      state.isLoading = false;
      state.pendants = action.payload;
    },
    inventoryPendantsList(state, action) {
      state.isLoading = false;
      state.PendantsList = action.payload;
    },
    PendantsById(state, action) {
      state.isLoading = false;
      state.PendantsId = action.payload;
    },
    // INVENTORY BRACELET
    createBraceletInventory(state, action) {
      state.isLoading = false;
      state.bracelet = action.payload;
    },
    inventoryBraceletList(state, action) {
      state.isLoading = false;
      state.BraceletList = action.payload;
    },
    BraceletById(state, action) {
      state.isLoading = false;
      state.BraceletId = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { GemstoneList, UnmountList, DiamondList, diamond, deleteMasterCosts, createmasterCosts } = slice.actions;

// ----------------------------------  Create Inventory Diamond ------------------------------------

export function CreateDiamondInventory(diamond) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/inventory-diamonds', diamond);
      return dispatch(slice.actions.createDiamondInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------  Update Inventory Diamond ------------------------------------

export function UpdateDiamondInventory(diamond) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/inventory-diamonds/${diamond.id}`, diamond);
      return dispatch(slice.actions.createDiamondInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------- Inventory Diamond List -------------------------------------

export function getInventoryDiamond() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/inventory-diamonds');

      dispatch(slice.actions.inventoryDiamondList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory Diamond Id wise List -------------------------------------

export function getDiamondById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory-diamonds/${id}`);
      dispatch(slice.actions.diamondById(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------  Create Inventory Gemstone ------------------------------------

export function CreateGemstoneInventory(gemstone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/inventory_gemstones', gemstone);
      return dispatch(slice.actions.createGemstoneInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------  Update Inventory Gemstone ------------------------------------

export function UpdateGemstoneInventory(gemstone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/inventory_gemstones/${gemstone.id}`, gemstone);
      return dispatch(slice.actions.createGemstoneInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------- Inventory Gemstone List -------------------------------------

export function getInventoryGemstone() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/inventory_gemstones');

      dispatch(slice.actions.inventoryGemstoneList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory Gemstone Id wise List -------------------------------------

export function getGemstoneById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory_gemstones/${id}`);
      dispatch(slice.actions.gemstoneById(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------  Create Inventory Unset mount ------------------------------------

export function CreateUnmountInventory(unmount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/inventory-unmount', unmount);
      return dispatch(slice.actions.createUnmountInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------  Update Inventory Unset mount ------------------------------------

export function UpdateUnmountInventory(unmount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/inventory-unmount/${unmount.id}`, unmount);
      return dispatch(slice.actions.createUnmountInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------- Inventory Unset mount List -------------------------------------

export function getInventoryUnmount() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/inventory-unmount');

      dispatch(slice.actions.getInventoryUnmount(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory Unset mount Id wise List -------------------------------------

export function getUnmountById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory-unmount/${id}`);
      dispatch(slice.actions.getUnmountById(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory Unset mount design id wise stock no generate  -------------------------------------

export function fetchStockByDesignId(designId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory-unmount/max-stock/${designId}`);
      dispatch(slice.actions.getstockBydesignId(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------  Create Inventory set mount ------------------------------------

export function CreateSetmountInventory(unmount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/inventory-setmount', unmount);
      return dispatch(slice.actions.createSetmountInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------  Update Inventory set mount ------------------------------------

export function UpdateSetmountInventory(unmount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/inventory-setmount/${unmount.id}`, unmount);
      return dispatch(slice.actions.createSetmountInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------- Inventory set mount List -------------------------------------

export function getInventorySetmount() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/inventory-setmount');

      dispatch(slice.actions.getInventorySetmount(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory set mount Id wise List -------------------------------------

export function getSetmountyId(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory-setmount/${id}`);
      dispatch(slice.actions.SetmountById(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory set mount design id wise stock no generate  -------------------------------------

export function getSetmountStockId(designId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory-setmount/max-stock/${designId}`);
      dispatch(slice.actions.SetmountStockId(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------  Create Inventory Diamond ------------------------------------

export function CreateEarringsInventory(earrings) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/inventory-earrings', earrings);
      return dispatch(slice.actions.createEarringsInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------  Update Inventory Diamond ------------------------------------

export function UpdateEarringsInventory(earrings) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/inventory-earrings/${earrings.id}`, earrings);
      return dispatch(slice.actions.createDiamondInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------- Inventory Diamond List -------------------------------------

export function getInventoryEarrings() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/inventory-earrings');

      dispatch(slice.actions.inventoryEarringsList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory Diamond Id wise List -------------------------------------

export function getEarringsById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory-earrings/${id}`);
      dispatch(slice.actions.EarringsById(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------  Create Inventory Pendants ------------------------------------

export function CreatePendantsInventory(pendants) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/inventory-pendants', pendants);
      return dispatch(slice.actions.createPendantsInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------  Update Inventory Pendants ------------------------------------

export function UpdatePendantsInventory(pendants) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/inventory-pendants/${pendants.id}`, pendants);
      return dispatch(slice.actions.createPendantsInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------- Inventory Pendants List -------------------------------------

export function getInventoryPendants() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/inventory-pendants');

      dispatch(slice.actions.inventoryPendantsList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory Pendants Id wise List -------------------------------------

export function getPendantsById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory-pendants/${id}`);
      dispatch(slice.actions.PendantsById(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------  Create Inventory Bracelet ------------------------------------

export function CreateBraceletInventory(bracelet) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/inventory-bracelet', bracelet);
      return dispatch(slice.actions.createBraceletInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------  Update Inventory Bracelet ------------------------------------

export function UpdateBraceletInventory(bracelet) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/inventory-bracelet/${bracelet.id}`, bracelet);
      return dispatch(slice.actions.createBraceletInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------- Inventory Bracelet List -------------------------------------

export function getInventoryBracelet() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/inventory-bracelet');

      dispatch(slice.actions.inventoryBraceletList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory Bracelet Id wise List -------------------------------------

export function getBraceletById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory-bracelet/${id}`);
      dispatch(slice.actions.BraceletById(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
