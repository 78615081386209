import { createSlice } from '@reduxjs/toolkit';
import Axios from '../../utils/axiosInstance';

const initialState = {
  MasterCostsList: [],
  masterCost: null,
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'cost',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    createmasterCosts(state, action) {
      state.isLoading = false;
      state.masterCost = action.payload;
    },
  
    getMasterCostListSuccess(state, action) {
      state.isLoading = false;
      state.MasterCostsList = action.payload;
    },
    deleteMasterCosts(state, action) {
      const updatedmasterCosts = state.masterCost.filter((mastercost) => mastercost.id !== action.payload);
      state.masterCost = updatedmasterCosts;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { MasterCostsList, deleteMasterCosts, createmasterCosts } = slice.actions;

// ----------------------------------  Create Master Cost ------------------------------------


export function dispatchCreateMasterCost(masterCost) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/order-cost-master', masterCost);
      return dispatch(slice.actions.createmasterCosts(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error; 
    }
  };
}


// -----------------------Master Cost List-------------------------------------

export function getMasterCostList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/order-cost-master');

      dispatch(slice.actions.getMasterCostListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------Master Cost delete-------------------------------------


export function dispatchDeleteMasterCost(mastercostId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await Axios.delete(`/order-cost-master/${mastercostId}`);
      dispatch(slice.actions.deleteMasterCosts(mastercostId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
