import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function usePreviousPath() {
  const location = useLocation();
  const prevPathRef = useRef(null);

  useEffect(() => {
    const currentPath = location.pathname;
    // Update the previous path after rendering
    return () => {
      prevPathRef.current = currentPath;
    };
  }, [location.pathname]);

  return prevPathRef.current;
}
