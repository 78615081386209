import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------


export default function Logo() {
  return (
    <Box sx={{ width: 40, height: 40 }}>
      <img src="/static/images/flawless_logo.jpg" alt="logo" style={{ width: '100%', height: '100%' }} />
    </Box>
  );
}
