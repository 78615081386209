import { Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function UserNameTitle({ userName, variant = 'subtitle1', sx }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      <Typography variant={variant} noWrap >
        {userName}
      </Typography>
    </Box>
  );
}

UserNameTitle.propTypes = {
  userName: PropTypes.string,
  variant: PropTypes.string,
  sx: PropTypes.object
};
