// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_USER = '/user';
const ROOTS_STORE = '/store';
const ROOTS_INVOICE = '/invoice';
const ROOTS_COST = '/cost';
const ROOTS_REPORT = '/report';
const ROOTS_SALESREPORT = '/salesreport';
const ROOTS_TASK = '/task';
const ROOTS_MANUFACTURE = '/manufacture';
const ROOTS_INVENTORY = '/inventory';
const ROOTS_PRODUCT = '/product';
const ROOTS_DESIGNDETAIL = '/designdetail';



// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
};
export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',  
  confirmationorder: `/confirmation-order/orderId`,
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },  
  user: {
    root: ROOTS_USER,
    list: path(ROOTS_USER, '/list'),
    profile: path(ROOTS_USER, '/profile')
  },
  store: {
    root: ROOTS_STORE,
    list: path(ROOTS_STORE, '/list'),
  },
  invoice: {
    root: ROOTS_INVOICE,
    list: path(ROOTS_INVOICE, '/list'),
    newCustomer: path(ROOTS_INVOICE, '/customer/new'),
  },
  manufacture: {
    root: ROOTS_MANUFACTURE,
    list: path(ROOTS_MANUFACTURE, '/list'),
    remakelist: path(ROOTS_MANUFACTURE, '/remakelist'),
    resizelist: path(ROOTS_MANUFACTURE, '/resizelist'),
    repairlist: path(ROOTS_MANUFACTURE, '/repairlist'),
  },
  inventory: {
    root: ROOTS_INVENTORY, 
    diamond: path(ROOTS_INVENTORY, '/diamond'),   
    gemstone: path(ROOTS_INVENTORY, '/gemstone'),
    unmount: path(ROOTS_INVENTORY, '/unmount'),
    setmount: path(ROOTS_INVENTORY, '/setmount'),
    earrings: path(ROOTS_INVENTORY, '/earrings'),
    pendants: path(ROOTS_INVENTORY, '/pendants'),
    bracelet: path(ROOTS_INVENTORY, '/bracelet')
  },
  designdetail:  {
    root: ROOTS_DESIGNDETAIL,
    list: path(ROOTS_DESIGNDETAIL, '/list'),
  },
  cost: {
    root: ROOTS_COST,
    list: path(ROOTS_COST, '/list'),
  },
  report: {
    root: ROOTS_REPORT,
    list: path(ROOTS_REPORT, '/list'),
  },
  salesreport: {
    root: ROOTS_SALESREPORT,
    list: path(ROOTS_SALESREPORT, '/list'),
  },
  task: {
    root: ROOTS_TASK,
    list: path(ROOTS_TASK, '/list')
  },
  product: {
    root: ROOTS_PRODUCT,
    list: path(ROOTS_PRODUCT, '/list')
  },
};

